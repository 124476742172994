@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

body {
  font-family: "Work Sans", sans-serif;
}

.parallax-hero .react-parallax-bgimage {
  height: 646px !important;
  width: 100% !important;
  object-fit: cover;
}

@media (max-width: 768px) {
  .parallax-hero .react-parallax-bgimage {
    height: 500px !important;
    width: 100% !important;
  }
}